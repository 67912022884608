// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'FormsCSS Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: null,
    bannerContent: '<h1>banner overskrift</h1><p>banner manchet</p>',
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aqua,
        },
        componentChildren: [],
      },
    ],
    desktopTools: {},
  },
  pageContent: [
    // HTML Render

    {
      componentName: 'HtmlRender',
      componentData: {
        html:
          '<form class="formContainer">'
          + '<div class="errorBox">'
          + '<div class="errorText">Udfyld venligst nedestående felter</div>htrhwrhwrth</div>'
          + 'form med class="formBox"<br/><br/>'
          + '<label>Text Input</label> <br/>'
          + '<input type="text" placeholder="skriv tekst her" />'
          + '<br/><br/>'
          + '<label class="required">Email Input</label> <br/>'
          + '<input type="email" placeholder="skriv email her" required="required" />'
          + '<br/><br/>'
          + '<label class="required">Number Input</label> <br/>'
          + '<input type="number" placeholder="skriv nummer her" required="required" />'
          + '<br/><br/>'
          + '<label class="required">Password Input</label> <br/>'
          + '<input type="password" placeholder="skriv password her" required="required" />'
          + '<br/><br/>'
          + '<input type="radio" name="gender" value="male" checked> Male<br>'
          + '<input type="radio" name="gender" value="female"> Female<br>'
          + '<input type="radio" name="gender" value="other"> Other'
          + '<br/><br/>'
          + '<input type="checkbox" id="fruit1" name="fruit-1" value="Apple">'
          + '<label for="fruit1">Apple</label>'
          + '<br/>'
          + '<input type="checkbox" id="fruit2" name="fruit-2" value="Banana" checked>'
          + '<label for="fruit2">Banana</label>'
          + '<br/><br/>'
          + '<select>'
          + '<option value="volvo">Volvo</option>'
          + '<option value="saab">Saab</option>'
          + '<option value="mercedes">Mercedes</option>'
          + '<option value="audi">Audi</option>'
          + '</select>'
          + '<br/><br/><div class="btnContainer"><input type="submit" value="Submit" />'
          + '<input type="reset" value="Reset" /></div>'
          + '</form>',
      },
    },
  ],
};

export default Data;
